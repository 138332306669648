/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
/* eslint-disable max-len */
import * as React from "react";
import { Map } from "immutable";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../store/ActionTypes";
import { toggleSidebar } from "../../../store/AppActions";
import { isRemoteLoading, ReduxState, RemoteErrors } from "../../../store/ReduxState";
import backgroundImage from "../../assets/images/sidebar-bg.png";
import { LogoutBtn } from "../05-atoms/keycloaklogoutbtn";
import logoImageVWPFS from "../../assets/images/logos/vwpfs/logo-contrast.svg";
import { UserInfo } from "../../../store/AppTypes";
import { KeycloakLoggedIn, KeycloakNotLoggedIn } from "../05-atoms/keycloakLoggedIn";
import { PageDisplay, Pages } from "../../../store/AppDisplays";
import { InitApp } from "../../../store/Init/Types";
import { RemoteScope } from "../../../store/RemoteTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ReleaseVersion } from "../05-atoms/ReleaseVersion";

/**
 *
 */
interface StateProps {
    sidebarCollapsed: boolean;
    user: Mary.utils.Optional<UserInfo>;
    init?: InitApp;
    isLoading: boolean;
    isAuthenticated: boolean;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
}

/**
 *
 */
interface DispatchProps {
    toggleSidebar: (force?: boolean) => void;
    setFocus: () => void;
}

type Props = StateProps & DispatchProps;


/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    sidebarCollapsed: s.prop("sidebarCollapsed"),
    user: s.prop("user"),
    init: s.prop("remote").prop(RemoteScope.INIT),
    remoteErrors: s.prop("remoteErrors"),
    isAuthenticated: !isRemoteLoading(s, RemoteScope.INIT) && !s.prop("remoteErrors").get(RemoteScope.INIT) && !!s.prop("remote").prop(RemoteScope.INIT)?.roles?.length,
    isLoading: isRemoteLoading(s, RemoteScope.INIT),
});


/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    toggleSidebar: (force?: boolean) => dispatch(toggleSidebar(force)),
    setFocus: () => dispatch(Mary.utils.flexSetFocus(Mary.base.FlexWrapper.FlexWrapperFocus.SIDEBAR_INACTIVE)),
});

/**
 *
 * @param _props
 */
class SideBarComp extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
        this.updateCollapseState = this.updateCollapseState.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.updateCollapseStateWithIcon = this.updateCollapseStateWithIcon.bind(this);
    }

    public render() {
        return (
            <Mary.base.BaseConsumer>{({ getTheme }) =>
                (
                    <Mary.utils.SwipeBlock detectSwipeDir={(value: string) => this.updateCollapseState(value === "Left")}>
                        <Mary.base.flex.FlexSidebar isCollapsed={this.props.sidebarCollapsed}>
                            <Mary.utils.BreakpointConsumer>
                                {(value) => (
                                    <>
                                        <Mary.base.flex.FlexRow theme={{ palette: Mary.theme.ThemePalette.BRAND_PRIMARY }}>
                                            <Mary.base.flex.FlexColumn hasScrollable className="v-sidebar"
                                                style={{ backgroundImage: `url(${backgroundImage})` }}
                                            >
                                                <Mary.base.flex.FlexRow
                                                    isFixed
                                                    className="v-sidebar__userinfo"
                                                >
                                                    <Mary.organisms.NavbarStackContent>
                                                        {
                                                            (!this.props.sidebarCollapsed || !value.breakpointAtSM) ? (
                                                                <>
                                                                    <KeycloakLoggedIn>
                                                                        {this.props.isAuthenticated ? (
                                                                            <Mary.molecules.UserInfo
                                                                                name={this.props.user.get().userName || (Mary.utils.getText("app.userNotAuthenticated", "Can't authenticate user!") ?? "")}
                                                                                role={"VWPFS Bikes"}
                                                                                // eslint-disable-next-line react/no-children-prop
                                                                                children={this.props.isLoading && <Mary.atoms.loadingindicator.LoadingIndicator type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                                                                                    theme={{ palette: Mary.theme.ThemePalette.CONTRAST_ACCENT }} />}
                                                                            />) : (
                                                                            <Mary.molecules.UserInfo
                                                                                name={Mary.utils.getText("app.userNotAuthenticated", "Can't authenticate user!") ?? ""}
                                                                                // eslint-disable-next-line react/no-children-prop
                                                                                children={this.props.isLoading && <Mary.atoms.loadingindicator.LoadingIndicator type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                                                                                    theme={{ palette: Mary.theme.ThemePalette.CONTRAST_ACCENT }} />}
                                                                            />)
                                                                        }
                                                                    </KeycloakLoggedIn>
                                                                    <KeycloakNotLoggedIn>
                                                                        <Mary.molecules.UserInfo
                                                                            name={"Not loggedin"}
                                                                        />
                                                                    </KeycloakNotLoggedIn>
                                                                </>)
                                                                : <></>}
                                                    </Mary.organisms.NavbarStackContent>
                                                    <Mary.organisms.NavbarStackRight isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}>
                                                        {
                                                            <>
                                                                <>
                                                                    {(this.props.sidebarCollapsed && value.breakpointAtSM) &&
                                                                        <Mary.organisms.NavbarIcon>
                                                                            <Mary.atoms.button.Button
                                                                                className={"scl-o-navbar__icon"}
                                                                                link={{ href: "/" }}
                                                                                icon="home" />
                                                                        </Mary.organisms.NavbarIcon>
                                                                    }
                                                                    <>
                                                                        <KeycloakLoggedIn>
                                                                            {value.breakpointAtSM &&
                                                                                <Mary.organisms.NavbarIcon>
                                                                                    <LogoutBtn />
                                                                                </Mary.organisms.NavbarIcon>
                                                                            }
                                                                        </KeycloakLoggedIn>
                                                                    </>
                                                                </>
                                                                {!value.breakpointAtSM &&
                                                                <Mary.organisms.NavbarIcon>
                                                                    <Mary.atoms.button.Button
                                                                        className={"scl-o-navbar__icon"}
                                                                        link={{
                                                                            onClick: this.setFocus(),
                                                                        }}
                                                                        icon={Mary.atoms.IconNames.TIMES}
                                                                    />
                                                                </Mary.organisms.NavbarIcon>
                                                                }
                                                            </>
                                                        }
                                                    </Mary.organisms.NavbarStackRight>
                                                </Mary.base.flex.FlexRow>
                                                <Mary.base.flex.FlexRow isScrollable className="v-sidebar__menu">
                                                    <Mary.base.flex.FlexColumn className="v-sidebar__menu-content">
                                                        {(this.props.isAuthenticated && (!this.props.sidebarCollapsed || !value.breakpointAtSM)) &&
                                                            <Mary.views.blocks.DashboardMenu
                                                                tabs={Object.keys(PageDisplay).map((page): Mary.views.sections.PageType => ({
                                                                    ...PageDisplay[page as Pages],
                                                                    title: Mary.utils.getText(PageDisplay[page as Pages].title) ?? "n.a.",
                                                                }))}
                                                            />
                                                        }
                                                        <Mary.base.flex.FlexRow className={(this.props.sidebarCollapsed && value.breakpointAtSM) ? "v-sidebar__version-collapsed" : "v-sidebar__version"} isCollapsed={this.props.sidebarCollapsed || !value.breakpointAtSM}>
                                                            {(!this.props.sidebarCollapsed || !value.breakpointAtSM) &&
                                                            <>
                                                                <div className="v-sidebar__title">
                                                                    {Mary.utils.getText("app.app-title", "Bikes Portal")}
                                                                </div>
                                                                <Mary.base.Logo img={logoImageVWPFS} theme={getTheme()} variant={Mary.theme.ThemeVariants.CONTRAST} className="v-sidebar__logo--vwpfs" />
                                                            </>
                                                            }
                                                            <ReleaseVersion
                                                                isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}
                                                            />
                                                        </Mary.base.flex.FlexRow>
                                                    </Mary.base.flex.FlexColumn>
                                                </Mary.base.flex.FlexRow>
                                            </Mary.base.flex.FlexColumn>
                                        </Mary.base.flex.FlexRow>
                                        {(value.breakpointAtSM) && (
                                            <Mary.atoms.button.Button
                                                link={{ onClick: this.updateCollapseStateWithIcon(!this.props.sidebarCollapsed) }}
                                                theme={{
                                                    palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                                    shadow: Mary.theme.ThemeShadowSizes.TINY,
                                                }}
                                                className="m-info-card__icon"
                                                icon={this.props.sidebarCollapsed ? "chevron-right" : "chevron-left"}
                                                style={{ marginRight: "-35px", top: "50%" }}
                                            />
                                        )}
                                    </>
                                )}
                            </Mary.utils.BreakpointConsumer>
                        </Mary.base.flex.FlexSidebar>
                    </Mary.utils.SwipeBlock>
                )}
            </Mary.base.BaseConsumer>
        );
    }

    private updateCollapseStateWithIcon(collapse: boolean) {
        return () => this.updateCollapseState(collapse);
    }

    private updateCollapseState(collapse: boolean) {
        this.props.toggleSidebar(collapse);
    }

    private setFocus() {
        return () => this.props.setFocus();
    }
}

const SideBarWrapper: React.FunctionComponent = props => (<SideBarComp {...props as Props} />);

/**
 *
 */
export const SideBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideBarWrapper);
