/* eslint-disable react/display-name */
/* eslint-disable max-len */
import * as React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { ReduxState } from "../../../../store/ReduxState";
import { LogoutBtn } from "../../05-atoms/keycloaklogoutbtn";
import { Navbar } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Navbar";

interface OwnProps { }

interface DispatchProps {
    setFocus: () => void;
    closeModal: (id: string) => void;
}

interface StateProps {
}

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFocus: () => dispatch(Mary.utils.flexSetFocus(Mary.base.FlexWrapper.FlexWrapperFocus.SIDEBAR)),
    closeModal: (id: string) => {
        dispatch(Mary.utils.modalClose(id));
    },
});

const mapStateToProps = (_state: ReduxState): StateProps => ({
});

type Props = OwnProps & StateProps & DispatchProps;

class HomeNavBarComp extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
        this.setFocus = this.setFocus.bind(this);
    }

    public render() {
        return (
            <Navbar
                theme={{
                    palette: Mary.theme.ThemePalette.CONTRAST_PRIMARY,
                    shadow: Mary.theme.ThemeShadowSizes.SMALL,
                }}
                className={"scl-o-navbar__toggle-sidebar"}
            >
                <Mary.organisms.NavbarStack>
                    <Mary.organisms.NavbarStackLeft>
                        {
                            <Mary.organisms.NavbarIcon
                            >
                                <Mary.atoms.button.Button icon={"bars"}
                                    className="scl-o-navbar__icon"
                                    link={{ onClick: this.setFocus() }}
                                />
                            </Mary.organisms.NavbarIcon>
                        }
                    </Mary.organisms.NavbarStackLeft>
                    <Mary.organisms.NavbarStackContent>
                        <Mary.organisms.NavbarTitle>{Mary.utils.getText("app.app-title", "Bikes Portal")}</Mary.organisms.NavbarTitle>
                    </Mary.organisms.NavbarStackContent>
                    <Mary.organisms.NavbarStackRight>
                        <Mary.organisms.NavbarIcon>
                            <LogoutBtn />
                        </Mary.organisms.NavbarIcon>
                    </Mary.organisms.NavbarStackRight>
                </Mary.organisms.NavbarStack>
            </Navbar>
        );
    }

    private setFocus() {
        return () => this.props.setFocus();
    }
}

export const HomeNavBarWrapper: React.FunctionComponent<OwnProps> = props => (<HomeNavBarComp {...props as Props} />);

/**
 *
 */
export const HomeNavBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomeNavBarWrapper);

export const testUtil = {
    mapDispatchToProps: mapDispatchToProps,
    mapStateToProps: mapStateToProps,
};
