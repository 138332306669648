/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import "core-js";

import { I18n } from "@aws-amplify/core";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";

import {
    BrowserRouter as Router,
} from "react-router-dom";

import { unregister } from "../src/registerServiceWorker";
import { App } from "./App";
import { SideBar } from "./App/components/09-views/Sidebar";
import { VerifyContextWrapper } from "./App/utils/VerifyContextWrapper";
import { createReduxStore } from "./store/ReduxStore";
import { HomeNavBar } from "./App/components/09-views/00-blocks/NavBar";
import { KeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { KeycloakAuthenticated } from "./App/components/05-atoms/keycloakAuthenticated";
import AxiosProvider from "./App/utils/AxiosContext";
import { I18nAppTranslationsNL } from "./App/utils/translations/nl/I18n";
import "./App/assets/stylesheets/themes/vwpfs/index.scss";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

const elm = document.getElementById("root") as HTMLElement;

/**
 *
 */
enum ConfigProps {
    API_ENDPOINT = "data-api-endpoint",
}

I18n.configure(Mary.utils.I18nMarylocales);
I18n.putVocabularies({
    nl: {
        ...Mary.utils.I18nMaryTranslations.nl,
        ...I18nAppTranslationsNL,
    },
});

const store = createReduxStore({
    apiEndpoint: Mary.utils.ensure(elm.getAttribute(ConfigProps.API_ENDPOINT) ?? undefined),
});

const queryClient = new QueryClient();

/**
 *
 */
const Root: React.FunctionComponent<{}> = () => (
    <KeycloakProvider
        keycloak={keycloak}
        initConfig={{ onLoad: "login-required", checkLoginIframe: false }}
    >
        <Mary.base.BaseProvider
            theme={process.env.REACT_APP_THEME as Mary.theme.Themes}
            language={Mary.utils.I18nLanguage.DUTCH}
        >
            <Mary.utils.ErrorBoundary>
                <Provider store={store}>
                    <Router>
                        <KeycloakAuthenticated onAuthenticating={<Mary.base.PreLoader force />}>
                            <AxiosProvider>
                                <QueryClientProvider client={queryClient}>
                                    <Mary.utils.MaryProvider
                                        flexDefaultFocus={Mary.base.FlexWrapper.FlexWrapperFocus.SIDEBAR_INACTIVE}
                                    >
                                        <SideBar />
                                        <Mary.base.flex.FlexColumn isPage >
                                            <Mary.utils.BreakpointConsumer>
                                                {(value) => {
                                                    if (!value.breakpointAtSM) {
                                                        return (
                                                            <Mary.base.flex.FlexRow isFixed>
                                                                <HomeNavBar />
                                                            </Mary.base.flex.FlexRow>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                }}
                                            </Mary.utils.BreakpointConsumer>
                                            <Mary.molecules.EnvIndicatorBar />
                                            <Mary.base.flex.FlexRow
                                                theme={{ palette: Mary.theme.ThemePalette.CONTRAST_SECONDARY }}
                                            >
                                                <Mary.base.flex.FlexColumn hasScrollable>
                                                    <VerifyContextWrapper>
                                                        <App />
                                                    </VerifyContextWrapper>
                                                </Mary.base.flex.FlexColumn>
                                            </Mary.base.flex.FlexRow>
                                        </Mary.base.flex.FlexColumn>
                                        <Mary.base.PreLoader />
                                    </Mary.utils.MaryProvider>
                                </QueryClientProvider>
                            </AxiosProvider>
                        </KeycloakAuthenticated>
                    </Router>
                </Provider>
            </Mary.utils.ErrorBoundary>
        </Mary.base.BaseProvider>
    </KeycloakProvider>
);

ReactDOM.render(<Root />, elm);
unregister();
