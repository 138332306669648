/* eslint-disable @typescript-eslint/require-await */
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { Action } from "redux";
import { ActionTypeKeys, Thunk } from "./ActionTypes";
import {
    UserInfo,
} from "./AppTypes";
import { clearState, ReducerFn } from "./ReduxState";
import { remoteTrigger } from "./RemoteActions";
import { RemoteScope } from "./RemoteTypes";

export type AppInitActionType = Readonly<Action<ActionTypeKeys.APP_INIT> & { userInfo: UserInfo }>;
/**
 *
 */
export type AppInitAction = Readonly<
Action<ActionTypeKeys.APP_INIT> & {
    userInfo?: UserInfo;
}>;

export const appInit: (userInfo?: UserInfo) => Thunk<void> =
    (userInfo?: UserInfo) => async (dispatch, getState) => {

        // create action outside of dispatch for type checks.
        const action: AppInitAction = {
            type: ActionTypeKeys.APP_INIT,
            userInfo: userInfo,
        };

        const state = getState();
        let firstToken = true;
        if (state.prop("user").isPresent()) {
            console.log("Claude already initialised, updated token.");
            firstToken = false;
        } else {
            console.log("Claude is initialising, calling base API.");
        }

        // initialize the view
        dispatch(action);

        if (firstToken) {
        // trigger initial loading
            dispatch(remoteTrigger(RemoteScope.INIT, undefined));
        }
    };

export const appInitReducer: ReducerFn<AppInitActionType> =
(s, a) => {
    localStorage.setItem("token", a?.userInfo?.token ?? "");
    return (
        s.setProp("user", Mary.utils.Optional.of({ ...a.userInfo }))
    );
};


/**
 *
 */
export type SignOutAction = Readonly<
Action<ActionTypeKeys.SIGN_OUT>>;

export const signout: () => Thunk<void> =
    () => async (dispatch, _getState) => {
        dispatch({
            type: ActionTypeKeys.SIGN_OUT,
        });
    };

/**
 *
 */
export const signoutReducer:
ReducerFn<SignOutAction> =
     (s, _a) => clearState(s);

/**
 *
 */
export type ToggleSidebarAction = Readonly<
Action<ActionTypeKeys.TOGGLE_SIDEBAR> & {
    force?: boolean;
}>;

export const toggleSidebar: (force?: boolean) => Thunk<void> =
    (force) => async (dispatch, _getState) => {
        dispatch({
            type: ActionTypeKeys.TOGGLE_SIDEBAR,
            force,
        });
    };

/**
 *
 */
export const toggleSidebarReducer:
ReducerFn<ToggleSidebarAction> =
     (s, a) => {
         const current = a.force !== undefined ? a.force : !s.prop("sidebarCollapsed");
         localStorage.setItem("isCollapsed", JSON.stringify(current));
         return (
             s.setProp(
                 "sidebarCollapsed",
                 current,
             )
         );
     };
